import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";
// Components

const SectionDivider: React.FC<SectionDividerProps> = ({
  children,
  className,
  backgroundColor = "var(--color-gray-500)",
  fluid,
  maxWidth,
  minHeight = "55vh",
  marginTop,
  marginBottom,
  paddingTop = "4rem",
  paddingBottom = "4rem",
  position = "relative",
  style,
}) => {
  return (
    <StyledSection
      backgroundColor={backgroundColor}
      className={className}
      minHeight={minHeight}
      marginTop={marginTop}
      marginBottom={marginBottom}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      position={position}
      style={style}
    >
      <StyledContainer maxWidth={maxWidth}>
        {children}
        <BackgroundImage fluid={fluid} />
      </StyledContainer>
    </StyledSection>
  );
};

// Styled Components
const StyledSection = styled.section<StyledSectionProps>`
  &&& {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 0;
    overflow: hidden;

    min-height: ${({ minHeight }) => minHeight};
    padding-top: 5rem;
    padding-bottom: ${({ paddingBottom }) => paddingBottom};
    margin-top: ${({ marginTop }) => marginTop};
    margin-bottom: ${({ marginBottom }) => marginBottom};
    background-color: ${({ backgroundColor }) => backgroundColor};
    position: ${({ position }) => position};

    @media ${({ theme }) => theme.mq.sm} {
      padding-top: ${({ paddingTop }) => paddingTop};
    }
  }
`;
const StyledContainer = styled.div<StyledContainerProps>`
  margin: 0 auto;
  padding: 0 1.6rem;
  text-align: center;
  color: var(--color-white);
  z-index: 10;
  overflow: hidden;
  > *:first-child {
    margin-top: 0;
  }

  max-width: ${({ maxWidth }) => maxWidth};
`;
const BackgroundImage = styled(Img)<BackgroundImageProps>`
  position: absolute !important;
  overflow: hidden;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

// Types
type SectionDividerProps = {
  children: React.ReactNode | string;
  className?: string;
  backgroundColor?: string;
  fluid?: any;
  maxWidth?: string;
  minHeight?: string;
  marginTop?: string;
  marginBottom?: string;
  paddingTop?: string;
  paddingBottom?: string;
  position?: string;
  style?: React.CSSProperties;
};
type StyledSectionProps = {
  children: React.ReactNode | string;
  className?: string;
  backgroundColor?: string;
  minHeight?: string;
  marginTop?: string;
  marginBottom?: string;
  paddingTop?: string;
  paddingBottom?: string;
  position?: string;
};
type StyledContainerProps = {
  maxWidth?: string;
};
type BackgroundImageProps = {
  fluid: any;
};

export { SectionDivider };
