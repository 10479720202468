import React from "react";
// Components
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
// import { Section } from "../components/Section";
import { SectionDivider } from "../components/SectionDivider";
// import { Container } from "../components/Container";
import { PostList } from "../components/PostList";
import { TagList } from "../components/TagList";
import { Box, Container, Heading, Box as Section, Text } from "@chakra-ui/react";

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext;
  const { edges, totalCount } = data.allMdx;
  const tagHeader = `${totalCount} post${totalCount === 1 ? "" : "s"} tagged with `;

  return (
    <>
      <Helmet title={`My Posts - ${tag}`} />
      <Section backgroundColor="var(--color-gray-100)" mt="50px">
        <Container py={8} maxWidth="var(--screen-md)">
          <Heading color="brand.main" fontSize="md">
            {tagHeader}{" "}
            <Box display="inline-block" width="2rem" verticalAlign="middle">
              <svg
                style={{
                  verticalAlign: "middle",
                  marginRight: "0.2em",
                  marginLeft: "0.2em",
                }}
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="tags"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 640 512"
                className="svg-inline--fa fa-tags fa-w-20"
              >
                <path
                  fill="currentColor"
                  d="M497.941 225.941L286.059 14.059A48 48 0 0 0 252.118 0H48C21.49 0 0 21.49 0 48v204.118a48 48 0 0 0 14.059 33.941l211.882 211.882c18.744 18.745 49.136 18.746 67.882 0l204.118-204.118c18.745-18.745 18.745-49.137 0-67.882zM112 160c-26.51 0-48-21.49-48-48s21.49-48 48-48 48 21.49 48 48-21.49 48-48 48zm513.941 133.823L421.823 497.941c-18.745 18.745-49.137 18.745-67.882 0l-.36-.36L527.64 323.522c16.999-16.999 26.36-39.6 26.36-63.64s-9.362-46.641-26.36-63.64L331.397 0h48.721a48 48 0 0 1 33.941 14.059l211.882 211.882c18.745 18.745 18.745 49.137 0 67.882z"
                />
              </svg>
            </Box>
            {tag}
          </Heading>
          <PostList posts={edges} />
          <TagList />
        </Container>
      </Section>
      <SectionDivider
        fluid={data.backgroundImage.childImageSharp.fluid}
        maxWidth="var(--screen-sm)"
        css={`
          font-family: "Sedgwick Ave Display", cursive;
        `}
      >
        <div
          css={`
            position: absolute;
            inset: 0;
            background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8));
            z-index: 0;
          `}
        />
        <h4
          css={`
            position: relative;
            color: var(--color-secondary-light);
            font-weight: var(--font-weight-medium)
            opacity: 0;
            font-style: italic;
            letter-spacing: 0.1em;
            font-size: var(--font-size-6xl);
            text-shadow: 2px 2px 2px var(--color-black);
          `}
        >
          Tags
        </h4>
      </SectionDivider>
    </>
  );
};

export const pageQuery = graphql`
  query ($tag: String) {
    allMdx(
      limit: 2000
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { status: { eq: "published" }, tags: { in: [$tag] } } }
    ) {
      edges {
        node {
          frontmatter {
            status
            path
            heroImage {
              childImageSharp {
                fluid(quality: 90, maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            title
            description
            date
            tags
          }
          slug
          excerpt
        }
      }
      totalCount
    }
    allTags: allMdx(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
      }
    }
    backgroundImage: file(relativePath: { eq: "tags.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default Tags;
